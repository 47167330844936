import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Hero.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";


const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return <span>{currentText}</span>;
};

const Hero = () => {
  const taglineText = "One Solution, Triple Impact";
  const taglineText2 = "PEOPLE PLANET PROFIT";

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section className="hero-section d-flex align-items-center justify-content-center">
      <Helmet>
      <meta charSet="utf-8" />
        <title>
          Eco Bricks : Crafting Building Blocks from Non-Recyclable waste
        </title>
        <link rel="canonical" href={"http://theecobricks.com"} />
        <meta
          name="description"
          content="Innovative concrete alternative for a carbon-negative future. 
          Crafting premium building products from non-recycled plastics."
        />
        <meta
          name="tags"
          content="ecobricks, ecobricks in pakistan, ecobrick, ecobrick in pakistan,
            ecotiles, green tiles ecofriendly product, ecofriendly pavers, eco pavers,
            tilegreen, greenbricks, tufftile pavers the ecobricks tiles, construction
            material in pakistan, ecobrick ecotiles avaiable in pakistan, green tiles
            ecofriendly product, eco pavers, tiles, ecobrick ecotiles available in
            pakistan, ecobricks pakistan ecobricks construction material ecobricks
            building blocks ecobricks tiles ecobricks ecotiles eco-friendly pavers
            green bricks tufftile pavers tilegreen construction material in pakistan"
        />
      </Helmet>
      <Container id="home">
        <Row className="mb-4 text-center">
          <Col xs={12}>
            <div className="hero-tagline">
              <p>
                <Typewriter text={taglineText} delay={50} />
              </p>
              <p>
                <Typewriter text={taglineText2} delay={50} />
              </p>
            </div>
          </Col>
        </Row>
        <Row
          className="mb-4 text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="2000"
        >
          <Col xs={12}>
            <div className="hero-description">
              <p>
                Innovative concrete alternative for a carbon-negative future.
                Crafting premium building products from non-recycled plastics.
                Building the sustainable framework for tomorrow's cities.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
