import React from "react";
import "./Footer.css";
import { FaFacebook, FaLinkedin, FaInstagram } from "react-icons/fa";
import { BiMap, BiSolidBusiness } from "react-icons/bi";
import Logo from "../../assets/Fav_Icon.png";
import { MdOutlineMailOutline, MdCall } from "react-icons/md";

const Footer = () => {
  return (
    <footer className="footer-container" id="contact">
      <div className="left-section">
        <img src={Logo} alt="logo" />
        <div className="contact-info">
          <p>
            <MdCall /> : 000-0000000
          </p>
          <a
            className="a"
            href="mailto:contact@ecobricks.com?subject=Email Subject&body=Email Body"
          >
            <MdOutlineMailOutline /> : contact@ecobricks.com
          </a>
          <a
            className="a"
            href="https://maps.app.goo.gl/ZBHsDmJsghHDDpoK9"
            target="_blank"
            rel="noreferrer"
          >
            <BiSolidBusiness /> : NSTP, H12, Islamabad
          </a>
        </div>
        <div
        // className="view-map"
        >
          <a
            className="a"
            href="https://maps.app.goo.gl/ZBHsDmJsghHDDpoK9"
            target="_blank"
            rel="noreferrer"
          >
            {/* <BiMap className="location-icon" /> */}
            <BiMap /> : Open in Maps
          </a>
        </div>
      </div>
      <div className="social-links">
        <a
          href="https://www.facebook.com/ecobricks.pk?mibextid=eHce3h"
          target="_blank"
          rel="noreferrer"
          tag="facebook"
        >
          <FaFacebook className="social-icon" />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.linkedin.com/company/ecobricks1/"
          target="_blank"
          rel="noreferrer"
          tag="linkedin"
        >
          <FaLinkedin className="social-icon" />
        </a>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a
          href="https://www.instagram.com/ecobricks.pk?igsh=MTRiN29zbTYxNW5neQ=="
          target="_blank"
          rel="noreferrer"
          tag="instagram"
        >
          <FaInstagram className="social-icon" />
        </a>
      </div>
      <div className="right-section">
        <div className="newletter">
          <p>Subscribe for Updates</p>
          <div className="input-wrapper">
            <input type="email" placeholder="Your email here" />
            <button>Subscibe</button>
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="bottom-section">
        <div className="footer-links">
          <p>
            <a href="privacy-policy">privacy policy</a> |{" "}
            <a
              href="https://theecobricks.com/sitemap.xml"
              target="_blank"
              rel="noreferrer"
            >
              sitemap
            </a>
          </p>
        </div>
        <div className="copyright">
          <p>&copy; {new Date().getFullYear()} ecobricks</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
