import React from 'react';
import Hero from '../components/Hero/Hero'; 
import Heading from '../components/Heading/Heading';
import Products from '../components/Products/Products';
import SocialProof from '../components/SocialProof/SocialProof';
import Impact from '../components/Impact/Impact';
import Footer from '../components/Footer/Footer'; 
import Header from '../components/Header/Header';
import Story from '../components/Story/Story';
import CompanyProjects from '../components/CompanyProjects/CompanyProjects';
import ecobricksback from '../assets/ecobricksback.jpg'
import BrickColorsInsight from '../components/BrickColorsInsight/BrickColorsInsight'
import SDG from '../components/SDG/SDG'
import { animateScroll as scroll } from 'react-scroll';
import { FaArrowUp } from "react-icons/fa";

const LandingPage = () => {
  const headerStyle = {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${ecobricksback})`,
    // backgroundSize: 'cover',
    backgroundSize: "100% 100%",
    // maxwidth: "100%",
    maxheight: "100%",
    backgroundRepeat: 'no-repeat',
    // height: "20% 100%"
  };
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  const buttonRef = React.useRef(null);
  React.useEffect(() => {
    const handleScroll = () => {
      if (buttonRef.current) {
        if (window.scrollY > 0) {
          buttonRef.current.style.display = 'block';
        } else {
          buttonRef.current.style.display = 'none';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div>
      <section id='header' style={headerStyle}>
        <Header />
        <Hero /> 
      </section>
      <Heading title="Story" detail="Where the Journey Began" />
      <Story />
      <Heading title="PRODUCTS" detail="Innovation in Every Brick" />
      <Products />
      <Heading title="UNIQUENESS OF OUR TILES" detail="Discover the Unmatched Uniqueness of Our Tiles and Pavers" />
      <BrickColorsInsight />
      <Heading title="PROJECTS" detail="Under Construction" />
      <CompanyProjects />
      <Heading title="IMPACT" detail="Explore the Transformative Impact of Our Sustainable Solutions" />
      <Impact />
      <Heading title="SUSTAINABLE DEVELOPMENT GOALS" detail="Contributing to Global Progress and Sustainable Practices" />
      <SDG />
      <Heading title="SOCIAL PROOF" detail="Discover What Our Clients Say About Their Experiences" />
      <SocialProof />
      <Footer /> 
      <button onClick={scrollToTop} ref={buttonRef} className="floating-button"><FaArrowUp /> </button>
    </div>
  );
};

export default LandingPage;