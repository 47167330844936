import React from "react";
import { BrowserRouter as Router} from 'react-router-dom';
import LandingPage from "./pages/LandingPage";
import "./App.css";
import { Helmet } from "react-helmet-async";

function App() {
  return (
    <div>
      <Helmet>
      <meta charSet="utf-8" />
        <title>
          Eco Bricks : Crafting Building Blocks from Non-Recyclable waste
        </title>
        <link rel="canonical" href={"http://theecobricks.com"} />
        <meta
          name="description"
          content="Innovative concrete alternative for a carbon-negative future. 
          Crafting premium building products from non-recycled plastics."
        />
        <meta
          name="tags"
          content="ecobricks, ecobricks in pakistan, ecobrick, ecobrick in pakistan,
            ecotiles, green tiles ecofriendly product, ecofriendly pavers, eco pavers,
            tilegreen, greenbricks, tufftile pavers the ecobricks tiles, construction
            material in pakistan, ecobrick ecotiles avaiable in pakistan, green tiles
            ecofriendly product, eco pavers, tiles, ecobrick ecotiles available in
            pakistan, ecobricks pakistan ecobricks construction material ecobricks
            building blocks ecobricks tiles ecobricks ecotiles eco-friendly pavers
            green bricks tufftile pavers tilegreen construction material in pakistan"
        />
      </Helmet>
      {/* <Router> 
        <Routes>
          <Route exact path="/" component={LandingPage} />
        </Routes>
      </Router> */}
      <Router>
        <LandingPage />
      </Router>
      {/* <LandingPage /> */}
    </div>
  );
}

export default App;
