import React, { useState } from "react";
import Burger from "@animated-burgers/burger-arrow";
import "@animated-burgers/burger-arrow/dist/styles.css";
import { Image } from "react-bootstrap";
import "./Header.css";
// import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from "react-scroll";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const publicUrl = process.env.PUBLIC_URL;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <Image
        src={`${publicUrl}/logo-transparent-white.png`}
        alt="Ecobricks LOGO"
        className="logo"
      />
      <div
        className={`menu-icon ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <Burger isOpen={isMenuOpen} direction="down" />
      </div>
      {isMenuOpen && (
        <div className="dropdown">
          <ul className="dropdown-content">
            <li
              className="slide-in-right"
              style={{ fontSize: "1.1em", fontWeight: "650" }}
            >
              <LinkS
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-230}
                // duration={500}
                // onSetActive={handleSetActive}
              >
                About
              </LinkS>
            </li>
            <li
              className="slide-in-right"
              style={{ fontSize: "1.1em", fontWeight: "650" }}
            >
              <LinkS
                activeClass="active"
                to="product"
                spy={true}
                smooth={true}
                offset={-340}
                // duration={500}
                // onSetActive={handleSetActive}
              >
                Products
              </LinkS>
            </li>
            <li
              className="slide-in-right"
              style={{ fontSize: "1.1em", fontWeight: "650" }}
            >
              <LinkS
                activeClass="active"
                to="blog"
                spy={true}
                smooth={true}
                offset={-120}
                // duration={500}
                // onSetActive={handleSetActive}
              >
                Blog
              </LinkS>
            </li>
            <li
              className="slide-in-right"
              style={{ fontSize: "1.1em", fontWeight: "650" }}
            >
              <LinkS
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                // offset={-140}
                // duration={500}
                // onSetActive={handleSetActive}
              >
                Contact
              </LinkS>
            </li>
            <li
              className="slide-in-right"
              style={{ fontSize: "1.1em", fontWeight: "650" }}
            >
              <LinkS
                activeClass="active"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-120}
                // duration={500}
                // onSetActive={handleSetActive}
              >
                Gallery
              </LinkS>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
